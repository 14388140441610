
import { Component, Vue,Prop } from 'vue-property-decorator';
/* INTERFACES */
import {FrequentBeneficiary} from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces'
/* STORE */
import {beneficiaries} from '@/store/namespaces'
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
/* COMPONENTS */
import Loader from '@/components/utilities/Loader.vue';
import CreateFrequentBeneficiary from '@/modules/beneficiaries/components/createBeneficiary/CreateFrequentBeneficiary.vue';
import EditFrequentBeneficiary from '@/modules/beneficiaries/components/EditFrequentBeneficiary.vue';
import BeneficiaryDetailModal from '@/modules/beneficiaries/components/BeneficiaryDetailModal.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';

@Component({
  components: {
      CreateFrequentBeneficiary,
      EditFrequentBeneficiary,
      BeneficiaryDetailModal,
      ConfirmationModal,
      Loader,
      SvgIcon
  },
})
export default class FrequentBeneficiariesSection extends Vue {
    @Prop() frequentBeneficiaries!: FrequentBeneficiary[]
    perPage : number = 6;
    currentPage: number = 1;
    fields = [
        {key: 'country_code', label:'País'},
        {key:'nickname',label:'Alias'},
        {key:'owner_name',label:'Nombre'},
        {key:'currency_name',label:'Moneda'},
        {key:'name_pay_method',label:'Forma de pago'},
        // {key:'name_doc_type',label:'Tipo Doc. ID'},{key:'identification',label:'Doc. Identidad'},{key:'account',label:'Cuenta'},{key:'account_type',label:'Tipo de Cuenta'},{key:'email',label:'Correo/Tel'},
        {key:'name_bank',label:'Banco'},{key:'actions',label:'Detalle'}]
    createBeneficiaryModal = {
        open: false,
        beneficiary: null,
        type: '',
        title: ''
    };
    editBeneficiaryModal = {
        open: false,
        beneficiary: null
    };
    deleteConfirmationModal = false;
    showBeneficiaryModal = false;
    $refs: any ={};
    isBusy : boolean = false;
    selectedBeneficiaryId: string = '';
    selectedBeneficiary: FrequentBeneficiary = null;

    async mounted(){
    }

    /* MODAL */
    createBeneficiaryModalStatus(newVal: boolean) {
        this.createBeneficiaryModal.open = newVal;
    }
    editBeneficiaryModalStatus(newVal: boolean) {
        this.editBeneficiaryModal.open = newVal;
    }
    deleteConfirmationModalStatus(newVal: boolean){
        this.deleteConfirmationModal = newVal;
    }
    showBeneficiaryModalStatus(newVal: boolean){
        this.showBeneficiaryModal = newVal;
    }
    /* END MODAL */

    deleteBen(beneficiaryId: string){
        this.selectedBeneficiaryId = beneficiaryId;
        this.deleteConfirmationModal = true;
    }

    showBeneficiaryDetail(beneficiary: FrequentBeneficiary){
        this.selectedBeneficiary = beneficiary;
        this.showBeneficiaryModal = true;
    }

    async deleteConfirmed(){
        this.isBusy = true;
        this.$refs.loader.showLoader();
        let response = await this.deleteBeneficiary(this.selectedBeneficiaryId);
        this.isBusy = false;
        this.$refs.loader.hideLoader();
        if (response.error) showToast(response.msg,'error')
        else {
            this.$emit('fetchFrequentBeneficiaries');
            showToast(response.msg,'success')
        }
        this.selectedBeneficiaryId = '';
        this.deleteConfirmationModal = false;
    }

    refreshBeneficiaries(){
        // this.frequentBeneficiaries.push(newVal)
        this.isBusy = true;
        this.$emit('fetchFrequentBeneficiaries');
        this.isBusy = false;
    }

    @beneficiaries.Action(BeneficiariesMethods.actions.DELETE_FREQUENT_BENEFICIARY)
    deleteBeneficiary!: (beneficiaryId: string) => Promise<{msg: string, error: boolean}>
}

