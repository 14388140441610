
import { Component, Vue,Prop } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { ApexChartOptions, ApexChartSeries } from '@/modules/charts/interfaces/apexchart.interface';

@Component({
  components: {
      SvgIcon
  },
})
export default class DataCard extends Vue { 
    @Prop() title!: string;
    @Prop() data?: {
        data: string | number,
        big?: boolean,
        medium?: boolean
    };
    @Prop() styles!: string;
    @Prop() small?: boolean;
    @Prop() medium?: boolean;
    @Prop() img?: {
        source: string,
        alt: string,
        svg: boolean,
    }
    @Prop() chart?:{
        show: boolean,
        type: string,
        options: any,
        series: any,
    }
    @Prop() reverse?: boolean;
    @Prop() boldTitle?: boolean;

    $refs: any = {};

    get dataClass(){
      let classes = '';
      if (!this.small) {
        classes ='mt-2 text-end ';
        if (this.data.big) classes += 'fs-biggest'
        else classes += 'fs-bigger fw-bold'
      }
      else if (this.data.medium) classes = 'fs-bigg fw-bold'        
      else classes = 'fs-bigger fw-bold'
      return classes
    }

    get titleClass(){
        let classes = ''
        if (this.small) classes = classes + ' fs-smaller'
        else  classes = classes + ' fs-small text-start mt-3'
        if (this.boldTitle) classes = classes + ' fw-bold'
        return classes
    }

    get chartCol(){
        if (this.small) return '3'
        else if (this.medium) return '6'
        else return '7'
    }

    get infoCol(){
        if (this.small) return '9'
        else if (this.medium) return '6'
        else return '5'
    }
}
