
import { User } from '@/modules/auth/interfaces/user.interface';
import { capitalize, getGenderName, replaceBlankSpaces } from '@/utils/global-functions';
import { Component, Vue,Prop } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import DataCard from '@/components/utilities/DataCard.vue';
import { getStatusColor, getStatusFemaleName, getStatusIcon, getStatusName } from '@/modules/verificationLevels/functions/verifLevels.functions';
import {verifLevelsNames} from '@/modules/verificationLevels/functions/verifLevels.functions'
import { remittance } from '@/store/namespaces';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'
import { Remittance } from '@/modules/remittance/interfaces/remittance.interface';
import AmbassatorLinkSection from '@/modules/client/components/profile/AmbassatorLinkSection.vue';

@Component({
  components: {
      SvgIcon,
      DataCard,
      AmbassatorLinkSection
  },
})
export default class ProfileSection extends Vue {

    @Prop() userData!: User;
    @Prop() residCountryInSpanish!: string;

    async mounted(){
        await this.fetchRemittancesByStatus(this.userData.email_user)
    }

    get verifLevel(){
        return verifLevelsNames(this.userData.id_verif_level)
    }

    get spanishCountryName(){
        if (this.userData.iso_code_resid_country === 'DO') return 'R. Dom'
        else return this.residCountryInSpanish
    }

    get badgeName(){
        return getStatusName(this.userData.verif_level_apb);
    }

    get badgeColor(){
        return getStatusColor(this.userData.verif_level_apb);
    }

    get badgeIcon(){
        return getStatusIcon(this.userData.verif_level_apb);
    }



    capitalizeText(text: string){
      return capitalize(text)
    }

    /* REMITTANCES */

    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCES_BY_STATUS)
    fetchRemittancesByStatus!:(email_user: string)=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_TOTAL_REMITTANCES)
    totalRemittances!: number

}

