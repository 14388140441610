
import { Component, Vue } from 'vue-property-decorator';
/* STORE */
import {verifLevels,auth} from '@/store/namespaces'
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
/* INTERFACES */
import { WholeSalePartnerReq } from '@/modules/verificationLevels/interfaces/requirement.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* FUNCTIONS */
import { getStatusColor, getStatusIcon, getStatusFemaleName } from '@/modules/verificationLevels/functions/verifLevels.functions';
import { wholeSalePartnerApproval } from '@/modules/wholesalePartners/functions/approval.functions'
@Component({
  components: {
      SvgIcon,
  },
})
export default class WholesalePartnerSection extends Vue {
    // @Prop() wholeSalePartnerReqs!: {questions: WholeSalePartnerReq[], email_user: string};
    wholeSalePartnerRequestStatus : boolean | number = 0;
    loading = true

    async mounted(){
        // await this.fetchWholeSalePartnerReqs(this.userData.email_user);
        this.wholeSalePartnerRequestStatus = wholeSalePartnerApproval(this.wholeSalePartnerReqs.questions,this.wholeSalePartnerRequestStatus)
        this.loading=false;
    }

    get badgeName(){
        if (typeof this.wholeSalePartnerRequestStatus === 'number') return null
        else {
            return getStatusFemaleName(this.wholeSalePartnerRequestStatus)
        }
    }

    get badgeColor(){
        if (typeof this.wholeSalePartnerRequestStatus === 'number') return null
        else {
            return getStatusColor(this.wholeSalePartnerRequestStatus)
        }
    }

    get badgeIcon(){
       if (typeof this.wholeSalePartnerRequestStatus === 'number') return null
        else {
            return getStatusIcon(this.wholeSalePartnerRequestStatus)
        }
    }

    /*AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData! : User;  
    /* VERIF LEVELS */
    // @verifLevels.Action(VerifLevelMethods.actions.FETCH_WHOLESALEPARTNER_REQUIREMENTS)
    // fetchWholeSalePartnerReqs!: (emailUser: string) => Promise<boolean>
    @verifLevels.Getter(VerifLevelMethods.getters.GET_WHOLESALEPARTNER_REQUIREMENTS)
    wholeSalePartnerReqs!: {questions: WholeSalePartnerReq[], email_user: string}
}

