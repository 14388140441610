
import { Component, Vue, Prop } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/*INTERFACES*/
import { User } from '@/modules/auth/interfaces/user.interface';
/*FUNCTIONS*/
import { getStatusColor, getStatusFemaleName, getStatusIcon, getStatusName } from '@/modules/verificationLevels/functions/verifLevels.functions';
import {verifLevelsNames} from '@/modules/verificationLevels/functions/verifLevels.functions';

@Component({
  components: {
      SvgIcon,
  },
})
export default class NextLevelSection extends Vue {
    @Prop() userData!: User;

    get verifLevel(){
        return verifLevelsNames(this.userData.id_verif_level)
    }

    get badgeName(){
        return getStatusName(this.userData.verif_level_apb);
    }

    get badgeColor(){
        return getStatusColor(this.userData.verif_level_apb);
    }

    get badgeIcon(){
        return getStatusIcon(this.userData.verif_level_apb);
    }
}

