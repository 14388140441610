
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {payMethods, auth,beneficiaries} from '@/store/namespaces'
import AuthMethods from '@/store/auth/methods/auth.methods';
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods';
import BeneficiaryMethods from '@/store/beneficiaries/methods/beneficiaries.methods';
/* CONSTANTS */
import {FREQUENT_BENEFICIARY_EMPTY_STATE} from '@/modules/beneficiaries/emptyStates/frequentBeneficiary.emptyState';
/* INTERFACES */
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { FrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
/* COMPONENTS */
import ThirdStep from '@/modules/beneficiaries/components/createBeneficiary/ThirdStep.vue';
import Loader from '@/components/utilities/Loader.vue';

@Component({
    components:{
        ThirdStep,
        Loader
    }
})
export default class CreateFrequentBeneficiary extends Vue {
    @Prop() showModal!: {type: string, open: boolean,beneficiary: FrequentBeneficiary};
    show: boolean = false;
    beneficiary : FrequentBeneficiary = FREQUENT_BENEFICIARY_EMPTY_STATE;
    extraData : {selectedPayMethod: PayMethod}  ={
        selectedPayMethod: {idPayMethod: -1, name:''}
    }
    $refs : any = {}
    loading: boolean = false;
    payMethods: PayMethod[]=[]

    /* MODAL */
        @Watch('showModal.open')
        async renderModal(){
            this.show = this.showModal.open;
            this.loading = true;
            this.beneficiary = this.showModal.beneficiary
            this.extraData.selectedPayMethod =await this.fetchPayMethodById(this.showModal.beneficiary.id_pay_method)
            this.payMethods = await this.fetchPayMethodsByCountry(this.extraData.selectedPayMethod.idCountry)
            if (this.extraData === undefined || this.payMethods === undefined){
                this.show = false;
                showToast('Ha ocurrido un error','error')
            }
            this.extraData.selectedPayMethod.fields = this.payMethods.find(el=> el.idPayMethod === this.extraData.selectedPayMethod.idPayMethod).fields
            this.loading = false;
        }

        @Watch('show')
        sendToParent(newVal: boolean){
            this.$emit('changeModalStatus', newVal)
        }
    /* END MODAL */



    async submit(){
        let invalid = this.$refs.editedFields.validate();
        if (!invalid){
            this.$refs.loader.showLoader();
            let response = await this.updateBeneficiary({beneficiaryId: parseInt(this.beneficiary.id_beneficiary),beneficiary: this.beneficiary})
            this.$refs.loader.hideLoader();
            if (response.error === false){
                this.$emit('refreshBeneficiaries')
                showToast(response.msg,'success');
                this.show= false;
            }else showToast(response.msg,'error')
        }
    }

    /* BENEFICIARIES */
    @beneficiaries.Action(BeneficiaryMethods.actions.UPDATE_FREQUENT_BENEFICIARY)
    updateBeneficiary!: (params:{beneficiaryId: number,beneficiary:FrequentBeneficiary})=> Promise<{msg: string, error: boolean}>
    /* PAYMETHODS */
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHOD_BY_ID)
    fetchPayMethodById!: (payMethodId: number) => Promise<PayMethod | undefined>
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY)
    fetchPayMethodsByCountry!:(countryId: number) => Promise<PayMethod[] | undefined>
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
}

