
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* INTERFACES */
import {FrequentBeneficiary} from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces'
/* CONSTANTS */
/* FUNCTIONS */

@Component({
    components: {
        SvgIcon
    }
})
export default class RemittanceDetailPopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() beneficiary!: FrequentBeneficiary;
    show: boolean = false;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */

    deleteBeneficiary(){
        this.$emit('deleteBen',this.beneficiary.id_beneficiary)
        this.show=false;
    }

}
