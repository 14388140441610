
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods'
@Component({
  components: {
      SvgIcon,
  },
})
export default class AmbassatorLinkSection extends Vue {
    // link : string = 'https://cripto=applianceount/bubble.html'
    showCopyLinkTooltip = false;

    copyLink() {
      this.$copyText('¡Envía dinero a tus seres queridos con Bithonor! Es rápido, seguro y con las mejores tasas. Regístrate con mi enlace y participa por premios y beneficios todos los meses ' + this.link).then(function (e) {}, function (e) {})
      this.showCopyLinkTooltip = true;
      setTimeout(()=>{this.showCopyLinkTooltip = false}, 1000)
    }

    @auth.Getter(AuthMethods.getters.GET_AMBASSADOR_LINK)
    link!: string;
}
