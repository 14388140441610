
import { Component, Vue, Prop,Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { User } from '@/modules/auth/interfaces/user.interface';
import { capitalize, getGenderName, hideEmail, hideSomeCharacters, showToast } from '@/utils/global-functions';
import { dateToDDMMYYY, formatDate,isoDateToDDMMYYYY,usToSpanishFormat } from '@/utils/date-functions';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import {auth} from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods'
import { finishSession } from '@/modules/auth/functions/functions';
@Component({
  components: {
      SvgIcon
  },
  mixins: [validationMixin],
    validations:{
        
    }
})
export default class UserDataSection extends Vue {
    @Prop() userData!: User;
    @Prop() residCountryInSpanish!: string;
    @Prop() natCountryInSpanish!: string;
    registerDate = undefined;
    deactivateUser =  false;
    deactivateAccountEmail: string = '';
    deactivateAccountEmailError: boolean = false;

    created(){
        this.registerDate = new Date(this.userData.date_legacy_reg)
        this.registerDate = dateToDDMMYYY(this.registerDate)
        this.registerDate = formatDate(this.registerDate)
        this.registerDate = usToSpanishFormat(this.registerDate)
    }

    @Watch('deactivateAccountEmail')
    deactivateAccountEmailWatcher(newVal: string){
        if (newVal === this.userData.email_user){
            this.deactivateAccountEmailError = false
        }else this.deactivateAccountEmailError = true;
    }

    async deactivateAccount(){
        if (this.deactivateAccountEmailError === false && this.deactivateAccountEmail.length > 0){
            let response = await this.deactivate({email_user: this.userData.email_user});
            if (response.error === false){ 
                finishSession()
                this.$router.push({name: 'LogOut', params:{title: 'Usted ha desactivado su cuenta',subtitle:'¡Hasta la próxima!'}}).catch();
            }
            else {this.deactivateUser = false;showToast(response.msg,'error')}
        }else{
            this.deactivateAccountEmailError = true;
        }
    }


    get userDataBirthDate(){
        return  isoDateToDDMMYYYY(this.userData.date_birth)
    }

    get ofuscateEmail(){
        return hideEmail(this.userData.email_user)
    }
    get hiddenPhone(){
        if (this.userData.main_phone)
        return hideSomeCharacters(this.userData.main_phone,2,3)
        else return '-'
    }

    get hiddenBirthName(){
        if (this.userData.userDataBirthDate)
        return hideSomeCharacters(this.userDataBirthDate,0,0)
        return '-'
    }

    get hiddenDocType(){
        if (this.userData.name_ident_doc_type)
            return hideSomeCharacters(this.userData.name_ident_doc_type,0,0)
        return '-'
    }

    get hiddenStateName(){
        return hideSomeCharacters(this.userData.state_name,0,0)
    }

    get hiddenDocNumber(){
        return hideSomeCharacters(this.userData.ident_doc_number,0,3)
    }

    get hiddenRegisterDate(){
         return hideSomeCharacters(this.registerDate,0,0)
    }

    get hiddenGender(){
        return hideSomeCharacters(this.getGender(this.userData.gender),0,0) + '**'
    }

    getGender(genderKey: string){
       return getGenderName(genderKey)
    }

    capitalizeText(text: string){
      if (text) return capitalize(text)
      else return null
    }

    @auth.Action(AuthMethods.actions.DEACTIVATE_USER)
    deactivate!:(data:{email_user:string}) => Promise<{msg: string, error: boolean}>
}

