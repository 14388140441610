export function wholeSalePartnerApproval(questions: any[],status: boolean | number){
    if (questions === null)
            status = 0;
    else{
        status = true;
        for (let i=0;i<questions.length;i++){
            if (questions[i].question_apb_ok === false || 
                questions[i].question_apb_ok === null){
                status = questions[i].question_apb_ok;
                break;
            }
        }
    }
    return status
}