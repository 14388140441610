
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import NextLevelSection from '@/modules/client/components/profile/NextLevelSection.vue';
import WholesalePartnerSection from '@/modules/client/components/profile/WholesalePartnerSection.vue';
import FrequentBeneficiariesSection from '@/modules/client/components/profile/FrequentBeneficiariesSection.vue';
import AmbassatorLinkSection from '@/modules/client/components/profile/AmbassatorLinkSection.vue';
import ProfileSection from '@/modules/client/components/profile/ProfileSection.vue';
import UserDataSection from '@/modules/client/components/profile/UserDataSection.vue';
import AuthMethods from '@/store/auth/methods/auth.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods'
import {auth, beneficiaries, verifLevels, notifications} from '@/store/namespaces'
import { User } from '@/modules/auth/interfaces/user.interface';
import DataCard from '@/components/utilities/DataCard.vue';
import {getCountry} from 'country-list-spanish';
import { WholeSalePartnerReq } from '@/modules/verificationLevels/interfaces/requirement.interface';
import { FrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
import Loader from '@/components/utilities/Loader.vue';
import NotifMethods from '@/store/notifications/methods/verificationLevels.methods'
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';

@Component({
  components: {
      SvgIcon,
      NextLevelSection,
      WholesalePartnerSection,
      FrequentBeneficiariesSection,
      AmbassatorLinkSection,
      ProfileSection,
      UserDataSection,
      DataCard,
      Loader
  },
})
export default class Profile extends Vue {
    $refs: any = {};
    residCountryInSpanish: string = '';
    natCountryInSpanish: string = '';
    chartSeries = [{
                name: 'series1',
                data: [31, 20 , 97, 120],
            }
    ]
    chartOptions = {
            fill: {
            colors: ['#848fdf']
            },
            colors:['#6573d7']
            ,
            grid: {
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            chart: {
              type: 'area',
              toolbar:{
                show: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              labels:{
                  show: false,
              }
            },
            legend:{
                show: false
            },
            yaxis:{
                show: false
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
        }
        loading = true;

    async mounted(){
        this.loading = true;
        try{
            this.residCountryInSpanish = await getCountry(this.userData.iso_code_resid_country)
            this.natCountryInSpanish = await getCountry(this.userData.iso_code_nationality_country)
            if (!await this.fetchFrequentBeneficiariesLocal() || 
                !await this.fetchWholeSalePartnerReqs(this.userData.email_user)) throw new Error;
            this.loading = false;
        }
        catch(e){
            this.$router.push({name: 'ErrorPage'}).catch()
        }
    }

    async fetchFrequentBeneficiariesLocal(){
       return await this.fetchFrequentBeneficiaries({email_user: this.userData.email_user})
    }



    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
        userData! : User;  

    /* BENEFICIARIES */
    @beneficiaries.Action(BeneficiariesMethods.actions.FETCH_FREQUENT_BENEFICIARIES)
    fetchFrequentBeneficiaries!: (emailObj: any) => Promise<boolean>
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_FREQUENT_BENEFICIARIES)
    frequentBeneficiaries!: FrequentBeneficiary[]
    /* NOTIFICATIONS */
    @notifications.Getter(NotifMethods.getters.GET_MARQUEE_NOTIFICATIONS)
    marqueeNotifications!: Notif[];
    /* VERIF LEVELS */
    @verifLevels.Action(VerifLevelMethods.actions.FETCH_WHOLESALEPARTNER_REQUIREMENTS)
    fetchWholeSalePartnerReqs!: (emailUser: string) => Promise<boolean>

}
